import "./location-tunisia.scss"

import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
 query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "tunisia" }, node_locale: { eq: "en-US" }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
 locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationTunisiaPage = ({ data }) => {
    const pageTitle = "Careers - Tunisia"
    const pageDescription = "Get to Know Our Tunisia Offices"
    const lang = 'EN'
    const meta = []

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "Tunisia", url: "#" }
    ]

    const tunisia = {
        name: "Tunisia",
        text: "Situated on the Mediterranean shores, Tunis has a long and rich heritage; the city, then called Carthage, was the largest metropolis in the world at that time, and a deadly rival of Rome. Nowadays, Tunis is the commercial, political, and cultural center of Tunisia. Tunis is 2 to 3 hours away by plane to most European capital cities."
    }

    const location = {
        title: "Tunisia",
        cities: ["Tunisia"],
        sectionClassName: "section-location-tunisia",
        modalClassName: "modal-location-tunisia"
    }

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                <div className="max-container-pages">
                    <LocationHeader location={location} city={tunisia.name}>
                        <span className="city-title">{tunisia.name}</span>
                        <p className="city-text">
                            {tunisia.text}
                        </p>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="Tunisia"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationTunisiaPage
